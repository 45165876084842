import { useEffect, useState } from "react";
import { Upload } from "@aws-sdk/lib-storage";
import { S3Client, S3 } from "@aws-sdk/client-s3";
import axios from 'axios';

const bucket = process.env.REACT_APP_UPLOAD_BUCKET;
const region = process.env.REACT_APP_REGION;
const baseUrl = process.env.REACT_APP_BASE_URL;

const FileUpload = () => {

    //console.log(bucket);
    //console.log(region);
    const[status, setStatus] = useState('Upload not started');
    const[hostedUi, setHostedUi] = useState('');
    const[user, setUser] = useState(false);

    async function validateSession(){

        //check if a cookie exists with an expiration, if not then we definitely are not authenticated

        let hostedUI = process.env.REACT_APP_COGNITO_HOSTED_UI;
        setHostedUi(hostedUI);
        try{
        let resp = await axios.get(`${baseUrl}/auth-validation`,{ withCredentials: true });
        let auth = resp.data;
        if(auth != null){
            setUser(true);
        }
        
        console.log('AUTHENTICATED',auth);
        } catch(e){
            console.log(e);

        }

       
        let respCreds = await axios.get(`${baseUrl}/credentials`,{ withCredentials: true });
        let credentials = respCreds.data;
        if(credentials == null){
          setUser(false);
        }
        console.log('CREDENTIALS',credentials);
       
    }
    

    const upload = async (file) => {

        setStatus('File upload started');
        let fileToUpload = file.target.files[0];
        console.log(fileToUpload);

    
        //Get temporary credentials to s3 for this upload
        let credentialsEndpoint = `${baseUrl}/credentials`;
        console.log(credentialsEndpoint);
        let credentials = await axios.get(credentialsEndpoint,{ withCredentials: true });
        console.log('CREDENTIALS',credentials);

        const target = { Bucket:bucket, Key:fileToUpload.name, Body:fileToUpload };
    
        try {
            const parallelUploads3 = new Upload({
                client: new S3({
                    region: region,
                    credentials: credentials.data
                }) || new S3Client({}),
                tags: [{
                    Key: "source",
                    Value: "New Breed"}],
                leavePartsOnError: false, // optional manually handle dropped parts
                params: target,
            });
    
            parallelUploads3.on("httpUploadProgress", (progress) => {
                console.log('UPLOAD PROGRESS',progress);
            });
    
            parallelUploads3.done();
            
    
        } catch (e) {
            console.log(e);
        } 
       
        
    }

    useEffect( () => {
      validateSession();
      },[]);

    return (

       

        <div className="upload-container">
            <input type="file" onChange={upload}  />
            <div>Status: { status }</div>
            {user ? (
            <div>You are logged in</div>
          ) : (
            <div><a href={hostedUi}>login</a></div>
          )}
            
        </div>
    )
};

export default FileUpload;